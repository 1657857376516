<template>
  <b-card-code>
    <!-- <div v-for="permi in this.$store.state.app.user.permissions" :key="permi"></div> -->
    <!-- <p>{{ this.$store.state.app.user.permissions }}</p> -->
    <!-- <p class="text-primary">{{this.$store.state.app.user.permissions.includes('codename.add_application')}}</p> -->
    <b-row>
      <b-col class="col-12"><h3 class="text-primary">Applications</h3> </b-col>
      <b-col class="col-12">
        <b-row class="px-2 mt-1">
          <b-col class="col-4">
            <a href="https://one.valuementor.net/" target="_blank">
              <b-card class="text-left bgg-danger w-100">
                <div class="row justify-content-between align-items-center">
                  <div class="ml-1">
                    <h4 class="text-white mb-0 font-weight-bold">One.valuementor.net</h4>
                  </div>
                </div>
              </b-card>
            </a>
          </b-col>
          <b-col class="col-4">
            <a href="https://app.cywareness.com/" target="_blank">
              <b-card class="text-left bgg-danger w-100">
                <div class="row justify-content-between align-items-center">
                  <div class="ml-1">
                    <h4 class="text-white mb-0 font-weight-bold">Cywareness</h4>
                  </div>
                </div>
              </b-card>
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col><h3 class="text-primary">Connections</h3> </b-col>
      <b-col>
        <div class="d-flex justify-content-end">
          <div>
            <b-input-group class="input-group-merge">
              <!-- <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend> -->
              <b-form-input
                placeholder="Search Application"
                @change="searchFn"
                v-model="name_filter"
                size="sm"
              />
              <b-input-group-append>
                <b-button size="sm" variant="primary"
                  ><feather-icon
                    icon="SearchIcon"
                    class="text-white"
                    @click="searchFn"
                /></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button
            v-if="
              this.$store.state.app.user.permissions.includes(
                'application.add_application'
              )
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-1 ml-1"
            @click="gotoCreateApplication()"
          >
            Add
          </b-button>
          <!-- <b-button
            v-if=" this.$store.state.app.user.permissions.includes('application.add_application')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mr-1"
            @click="gotoApplicationDetails()"
          >
            App Details
          </b-button> -->
        </div>
      </b-col>
    </b-row>
    <!-- <button
      v-if="
        this.$store.state.app.user.permissions.includes(
          'organization.add_organization'
        )
      "
      @click="addOrg()"
      type="button"
      class="btn ml-auto btn-primary m-1"
    >
      Add New
    </button> -->

    <!-- <b-table
      responsive
      id="orgTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(application_name)="data">
        <a v-bind:href="data.item.application_link">{{data.item.application_name}}</a>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
    /> -->
    <!-- {{items}} -->
    <b-row class="px-2 justify-content-center">
      <b-col
        lg="4"
        class="d-flex align-items-center align-items-stretch"
        v-for="(item, i) in items"
        :key="i"
      >
        <b-card class="text-left bg-card-grey w-100">
          <div class="row justify-content-between align-items-center mb-1">
            <div class="ml-1">
              <h4 class="text-purple mb-0">{{ item.application_name }}</h4>
            </div>
            <div class="mr-1">
              <!-- <b-dropdown
                v-if="item.connection_status == true"
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <feather-icon icon="ExternalLinkIcon" />
                </template>
                <b-dropdown-item target="_blank" :href="item.application_link">{{item.application_link}}</b-dropdown-item>
              </b-dropdown> -->
              <a
                v-if="item.connection_status == true"
                :href="item.application_link"
                target="_blank"
              >
                <feather-icon icon="ExternalLinkIcon"
              /></a>
            </div>
          </div>

          <div class="row justify-content-between">
            <div class="">
              <b-button
                v-if="
                  $store.state.app.user.permissions.includes(
                    'application.view_application'
                  )
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="gotoDetails(item.id)"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
              <b-button
                v-if="
                  $store.state.app.user.permissions.includes(
                    'application.change_application'
                  )
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                @click="gotoEditApplication(item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <!-- v-if="
                  this.$store.state.app.user.permissions.includes(
                    'delete_application'
                  )
                " -->
              <b-button
                v-if="
                  $store.state.app.user.permissions.includes(
                    'application.delete_application'
                  )
                "
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="showModal(item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
            <div class="d-flex align-items-center">
              <b-button
                style="margin-right: 5px"
                v-if="$store.state.app.isAdmin"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                size="sm"
                class=""
                target="_blank"
                @click="showRevokeModal(item.id)"
              >
                Revoke All
              </b-button>
              <b-button
                v-if="item.connection_status == true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                class="mr-1"
                target="_blank"
                @click="disconnectFirewall(item.id)"
              >
                Disconnect
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mr-1"
                target="_blank"
                @click="connectFirewall(item.id)"
              >
                Connect
              </b-button>
            </div>
          </div>
          <div
            class="row justify-content-between"
            v-for="ip in item.user_last_connection"
            :key="ip.id"
            style="margin-top: 5px"
          >
            <div class="col-8">
              <span class="text-secondary mb-0 fs-11px">last connected:</span
              ><br />
              <p class="text-secondary mb-0 fs-11px">
                <span class="font-weight-bold">{{
                  new Date(ip.last_connected_time).toLocaleString()
                }}</span>
              </p>
            </div>
            <div
              class="col-4 d-flex align-items-center"
              v-if="item.connection_status == true"
            >
              <p class="text-primary mb-0 fs-11px">
                <span class="font-weight-bold">{{ ip.connected_ip }}</span>
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
      <div class="d-flex align-items-center justify-content-end col-12">
        <b-pagination-nav
          v-model="currentPage"
          :numberOfPages="total"
          :total-rows="rows"
          :link-gen="linkGen"
          :per-page="perPage"
          use-router
        ></b-pagination-nav>
      </div>
    </b-row>
    <b-modal
      id="modal-danger"
      ref="modal-danger"
      ok-only
      ok-variant="danger"
      @ok="deleteApplication(appid)"
      modal-class="modal-danger"
      centered
      title="Delete !"
    >
      <b-card-text>
        <!-- {{ this.appid }} -->
        Are You Sure to Delete the Application ?
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-danger-revoke"
      ref="modal-danger-revoke"
      ok-only
      ok-variant="danger"
      @ok="revoke(appid)"
      modal-class="modal-danger"
      centered
      title="Revoke !"
    >
      <b-card-text>
        The revoke action will clear all the inbound rules of all the firewalls
        connected to this application. This action is not reversible. Are you
        sure you want to continue?
        <!-- {{ this.appid }} -->
        <!-- Are You Sure to Revoke the Application ? -->
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BTable,
  BButton,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BCard,
    BCardText,
    BTable,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 9,
      rows: 0,
      total: 1,

      items: [],
      applog: [],
      appid: "",
      name_filter: "",
      connection: "",
      // userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },

  created() {
    this.load();
    // this.getApplog();
    this.appid = this.$route.params.id;
  },

  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    searchFn: function (reset = true) {
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "application/applications/" +
        "?page=" +
        this.currentPage;
      if (this.name_filter != null) {
        this.filter.push({ name: this.name_filter });
        url = url + "&q=" + this.name_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      console.log("Application list page");
      const options = {
        method: "GET",
        url: process.env.VUE_APP_BASEURL + "application/applications/",
      };

      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
      });
      this.searchFn();
    },
    // getApplog: function () {
    //   const options = {
    //     method: "GET",
    //     url: process.env.VUE_APP_BASEURL + "api/logs/logs/",
    //   };
    //   this.$http(options).then((res) => {
    //     this.applog = res.data;
    //   });
    // },
    gotoCreateApplication: function () {
      this.$router.push("/createapplication");
    },
    gotoApplicationDetails: function () {
      this.$router.push("/applicationdetails");
    },
    gotoEditApplication: function (id) {
      this.$router.push(`/editapplication/${id}`);
    },
    gotoDetails: function (id) {
      this.$router.push(`/appdetails/${id}`);
    },
    showModal(itemid) {
      this.$refs["modal-danger"].show();
      this.appid = itemid;
    },
    showRevokeModal(itemid) {
      this.$refs["modal-danger-revoke"].show();
      this.appid = itemid;
    },
    revoke(appid) {
      let data = {
        id: this.appid,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `application/applications/${appid}/revoke_rules/`,
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, " revoke");
        if (res.data.status_code === 204) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.load();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        // this.load();
        // window.open(res.data.application_link, '_blank')
      });
    },
    connectFirewall(appid) {
      let data = {
        id: this.appid,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `application/applications/${appid}/connect_firewall/`,
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status_code === 204) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.load();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }

        // window.open(res.data.application_link, '_blank')
      });
    },
    disconnectFirewall: function (appid) {
      let data = {
        id: this.appid,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `application/applications/${appid}/disconnect_firewall/`,
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, "disconnect connect");
        if (res.data.status_code === 204) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.load();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    deleteApplication: function (appid) {
      const data = {
        id: appid,
      };
      const options = {
        method: "DELETE",
        data: data,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `application/applications/${appid}/`,
      };
      this.$http(options)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted Application",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.load();
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.text-purple {
  color: #7367f0 !important;
}
.text-light-red {
  color: #f1416c !important;
}
.text-light-blue {
  color: #009ef7 !important;
}
.text-light-green {
  color: #50cd89 !important;
}
.icon-40px {
  width: 40px;
  height: 40px;
}
.bg-card-blue {
  background-color: #009ef7;
  color: #fff;
}
.bg-card-red {
  background-color: #f1416c;
}
.bg-card-green {
  background-color: #50cd89;
}

.bg-card-grey {
  background-color: #ecf0f3;
}
.card-boxshade {
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white !important;
}

.h-100-px {
  height: 100px !important;
}
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2),
    -12px -12px 24px 0 rgba(255, 255, 255, 0.5) !important;
}

.table-li {
  border-radius: 5px;
  color: #fff;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 110px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.neumorphic--pressed {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white !important;
  /* box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
    inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5) !important; */
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #7367f0 !important;
  color: #fff !important;
}
[dir] .table-bordered {
  border: 1px solid #7367f0 !important;
}

.fs-10 {
  font-size: 10px !important;
}

.bg-light-blue {
  background-color: #7367f0;
}

.bg-light-yellow {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43 !important;
  /* background-color: rgb(255 159 67 / 59%); */
}

.bg-light-red {
  background-color: rgba(234, 84, 85, 0.12);
  color: #ea5455 !important;
  /* background-color: rgb(255 14 16 / 47%); */
  /* background-color: rgb(255 14 16 / 52%); */
}
.bg-light-grey {
  background-color: rgb(36 35 35 / 12%);
  color: #a79596 !important;
}
.bg-green {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f !important;
  /* background-color: rgb(76 212 136); */
  /* background-color: rgb(40 199 111); */
}
.bg-blue {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.bg-light-green {
  background-color: rgb(40 199 111 / 43%);
}
ul {
  list-style-type: none;
  padding-left: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* li {
  padding-left: 5px !important;
} */

.fw-700 {
  font-weight: 700;
}

.fs-11px {
  font-size: 11px;
}
.search-input {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
}

.bgg-danger {
  background: #825cf9;
  background: -webkit-linear-gradient(top left, #825cf9, #8192d3);
  background: -moz-linear-gradient(top left, #825cf9, #8192d3);
  background: linear-gradient(to bottom right, #825cf9, #8192d3);
  /* background: #f7146f;
  background: -webkit-linear-gradient(top left, #f7146f, #d65762);
  background: -moz-linear-gradient(top left, #f7146f, #d65762);
  background: linear-gradient(to bottom right, #f7146f, #d65762); */
}
</style>
